<template>
    <div style="margin:-12px;width:100vw;">
        <!-- app bar -->
        <top-nav title="Perfil del cliente"></top-nav>

        <div v-if="customer != null">
            <v-card flat>
                <v-card-text>
                    <!-- ESTO PUDE SER EL DISEÑO DE LA PÁGINA DE PROFILE DEL CLIENTE -->
                    <div class="content">
                        <div class="text-center">
                            <v-avatar size="150" color="accent" class="avatar white--text">
                                <v-img :src="customerImage" v-if="customer.profilePic != null && customer.profilePic.key.length > 0"></v-img>
                                <h1 v-else>{{ getCustomerInitials() }}</h1>
                            </v-avatar>
                        </div>

                        <div class="headline text-center my-5">{{ customer.firstName }} {{ customer.lastName }}</div>

                        <v-list two-line>
                            <v-list-item @click="call" class="px-0">
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-phone</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ toPhone( customer.phone ) }}</v-list-item-title>
                                    <v-list-item-subtitle>Teléfono</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider inset></v-divider>

                            <v-list-item @click="newEmail" class="px-0">
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-email</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ customer.email }}</v-list-item-title>
                                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider inset></v-divider>

                            <v-list-item class="px-0">
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-numeric</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ customer.strikes == null ? 0 : customer.strikes }}</v-list-item-title>
                                    <v-list-item-subtitle>Strikes</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <!-- ADDRESS -->
                        <v-row v-if="showAddresses">
                            <v-col cols="6">
                                <v-subheader class="pl-0">Billing Address</v-subheader>
                                <address-component v-model="billingAddress"></address-component>
                            </v-col>
                            <v-col cols="6">
                                <v-subheader class="pl-0">Shipping Address</v-subheader>
                                <address-component v-model="shippingAddress"></address-component>
                            </v-col>
                        </v-row>

                        <v-divider class="my-5"></v-divider>
                        <!-- vacu id -->
                        <div class="subtitle-1 mb-2">Vacu ID</div>
                        <v-dialog v-model="dialog" v-if="hasVacuID">
                            <template v-slot:activator="{ on, attrs }">
                                <v-img :src="vacuIdImage" @click="dialog = true" contain max-height="150" v-bind="attrs" v-on="on"></v-img>
                            </template>

                            <v-card>
                                <v-card-title class="justify-space-between">
                                    <span>Vacu ID</span>
                                    <v-btn @click="dialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
                                </v-card-title>
                                <v-card-subtitle>Evidencia de vacunación</v-card-subtitle>
                                <v-card-text>
                                    <v-img :src="vacuIdImage" contain max-height="500"></v-img>
                                </v-card-text>

                                <v-card-actions>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <div v-else class="d-flex align-center">
                            <v-icon small class="mr-2"  color="orange">mdi-alert-outline</v-icon>
                            <span>No ha subido Vacu ID</span>
                        </div>
                        <v-divider class="my-5"></v-divider>


                        <!-- LATEST CHECK-INS -->
                        <div class="subtitle-1 mb-2">Últimos check-ins</div>

                        <v-list dense v-if="checkIns.length">
                            <template v-for="cI in checkIns">
                                <v-list-item :key="cI.id" class="px-0">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ cI.session.name }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span class="secondary--text">{{ cI.session.type }}</span> | {{ formatDate( cI.checkinDate, 'YYYY-MM-DD @ hh:mm a' ) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                        <div v-else>No tiene check-ins</div>

                        <v-divider class="my-5"></v-divider>

                        <!-- LATEST SUBSCRIPTIONS -->
                        <div class="subtitle-1 mb-2">Suscripciones</div>
                        
                        <v-list dense v-if="customer.subscriptions.length">
                            <template v-for="subscription in customer.subscriptions">
                                <v-list-item :key="subscription.id" class="px-0" :to="`/secure/product/${subscription.productId}`">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ subscription.product.name }}</v-list-item-title>
                                        <v-list-item-subtitle>Próxima fecha de pago: {{ formatDate( subscription.nextPaymentDate, 'YYYY-MM-DD' ) }}</v-list-item-subtitle>
                                        <v-list-item-subtitle>Auto-renovar: {{ subscription.autoRenew ? 'sí' : 'no' }}</v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="!isExpired(subscription.nextPaymentDate) && subscription.product.trainingType !== 'NA' && subscription.remainingSessions">Sesiones faltantes: {{ subscription.remainingSessions }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                        <div v-else>No tiene suscripciones</div>

                        <v-divider class="my-5"></v-divider>

                        <!-- LATEST ORDERS -->
                        <div class="subtitle-1 mb-2">Últimas 5 órdenes</div>

                        <v-list two-line dense v-if="orders.length">
                            <template v-for="(order, ix) in orders">
                                <v-list-item class="px-0" :key="order.id" :to="'/secure/order/' + order.id">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-left">{{ order.orderNumber.toUpperCase() }}</v-list-item-title>
                                        <v-list-item-subtitle class="text-left">
                                            {{ toMoney( order.total ) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-chip label x-small :color="getOrderStatusColor( order.status )" class="white--text">{{ order.status }}</v-chip>
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider inset v-if="ix < customer.orders.length - 1" :key="order.id + '-divider'"></v-divider>
                            </template>
                        </v-list>
                        <div v-else>No tiene ordenes</div>
                    </div>
                </v-card-text>
            </v-card>
        </div>

        <v-skeleton-loader class="mx-auto" type="avatar, sentences, sentences, sentences" v-else></v-skeleton-loader>
    </div>
</template>

<script>
import TopNav from '@/components/admin/TopNav';
import { PetuCustomer } from '@/classes/customer';
import { PetuSession } from '@/classes/session';
import { _st } from '@/softech';
import AddressComponent from '@/components/AddressComponent';
import moment from 'moment';

export default {
    props: {
        customerId      : { type: String, default: '' }
    },
    data: () => ({
        dialog          : false,
        customer        : null,
        customerImage   : null,
        heightFeet      : '',
        heightInches    : '',
        sessions        : null,
        vacuIdImage     : null,
    }),
    computed: {
        orders() {
            if( _st.isNU( this.customer ) ) return [];
            return this.customer.orders.sort((a,b) => (a.orderDate <= b.orderDate) ? 1 : ((b.orderDate <= a.orderDate) ? -1 : 0)).slice(0, 5);
        },
        checkIns() {
            if( _st.isNU( this.customer ) ) return [];
            return this.customer.checkIns.sort((a,b) => (a.checkinDate <= b.checkinDate) ? 1 : ((b.checkinDate <= a.checkinDate) ? -1 : 0)).slice(0, 5);
        },
        showAddresses() {
            return this.customer.billingAddress1.length > 0 || this.customer.shippingAddress1 > 0;
        },
        getVacuIDIcon() {
            return this.hasVacuID ? 'mdi-check-circle' : 'mdi-alert'
        },
        getVacuIDIconColor() {
            return this.hasVacuID ? 'success darken-1' : 'orange'
        },
        hasVacuID() {
            let c = new PetuCustomer(this.customer)
            return c.hasVacuID()
        },
        billingAddress() {
            return {
                name: `${this.customer.firstName} ${this.customer.lastName}`,
                line1: this.customer.billingAddress1,
                line2: this.customer.billingAddress2,
                city: this.customer.billingCity,
                state: this.customer.billingState,
                zipCode: this.customer.billingZipcode
            }
        },
        shippingAddress() {
            return {
                name: `${this.customer.firstName} ${this.customer.lastName}`,
                line1: this.customer.shippingAddress1,
                line2: this.customer.shippingAddress2,
                city: this.customer.shippingCity,
                state: this.customer.shippingState,
                zipCode: this.customer.shippingZipcode
            }
        },
    },
    async mounted() {
        // load product
        let customer = new PetuCustomer();

        if( _st.isNU( this.customerId ) || _st.isEmpty( this.customerId ) ) {
            this.customer = customer.data;
            return;
        }

        await customer.load( this.customerId );
        this.customer = {
            ...customer.data,
            checkIns: await customer.getCheckIns( 5 ),
            subscriptions: await customer.getSubscriptions(),
            orders: await customer.getOrders( 5 )
        };

        await customer.getProfilePicSrc();
        await customer.getVacuIDSrc();

        this.customerImage = customer.image.imgUrl;
        this.vacuIdImage = customer.vacuIdImage.imgUrl;

        this.sessions = await this.getSessions();

        if( !_st.isNUE( customer.data.height ) ) {
            this.heightFeet = customer.data.height.split(' ')[0].replace( "'", "" );
            this.heightInches = customer.data.height.split(' ')[1].replace( "''", "" );
        }
    },
    methods: {
        isAddressEmpty(addr) {
            return addr
            ? !addr.line1 && !addr.line2 && !addr.city && !addr.state && !addr.zipCode
            : false
        },
        getCustomerInitials() {
            let c = new PetuCustomer( this.customer );
            return c.getInitials();
        },
        call() {
            window.location.href = `tel:${this.customer.phone}`;
        },
        newEmail() {
            window.location.href = `mailto:${this.customer.email}`;
        },
        async getSessions() {
            if (_st.isNU(this.customer.checkIns)) return [];

            let sessions = [];
            for( const checkIn of this.customer.checkIns ) {
                let session = new PetuSession();
                await session.load(checkIn.sessionId);
                sessions.push(session.data);
            }

            return sessions;
        },
        formatDate( d, format ) {
            return moment(d).format(format);
        },
        toMoney( value ) {
            return _st.toMoney( value );
        },
        toPhone( value ) {
            return _st.toPhoneNumber( value );
        },
        getOrderStatusColor( status ) {
            switch( status ) {
                case 'processing'       : return 'orange';
                case 'completed'        : return 'green';
                case 'cancelled'        : return 'blue-grey';
                case 'pending payment'  : return 'amber';
                case 'shipped'          : return 'teal';
                case 'failed'           : return 'red';
                case 'draft'            : return 'grey';
                case 'refunded'         : return 'purple';
            }
        },
        isExpired(date) {
            date = new Date(date)
            return date < (new Date())
        },
    },
    components: {
        TopNav,
        AddressComponent
    }
}
</script>

<style lang="scss" scoped>
.content {
  margin: 80px 10px 0 10px;
//   background: rgb(238,58,140);
//   background: linear-gradient(187deg, rgba(238,58,140,1) 0%, rgba(246,137,38,1) 100%);
  border-radius: 20px;
}
.avatar {
    margin-top: -75px;
}
</style>